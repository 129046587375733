<template>
  <div class="inside-bg">
    <div class="container" style="padding-top: 100px">
      <nav area-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page" v-if="post">
            {{ post.title }}
          </li>
        </ol>
      </nav>
      <div class="col">
        <div class="row">
          <div class="col-lg-12 jobDetails">
            <div class="jobHeader" v-if="loading" style="height:200px;">
              <content-loader viewBox="0 0 476 50" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="loading">
                <rect x="0" y="8" rx="3" ry="3" width="150" height="10" />
              </content-loader>
            </div>
            <div class="jobHeader" v-if="post && !loading">
              <h1 v-if="post.title">{{ post.title }}</h1>
              <div v-if="post.link" class="howtoapply">
                <a class="btn btn-primary btn2" :href="post.link" v-if="isLogin || post.type==2">{{ post.label? post.label:'Open' }}</a>
                <a class="btn btn-primary btn2" :href="'/jobseeker/register?src='+post.src+'&&redirect_from='+encodeURIComponent(post.link)" v-else>{{ post.label? post.label:'Open' }}</a>
              </div>
            </div>
            <div class="jobDescription" v-if="post">
              <div class="row mb-1">
                <div class="col-md-8" v-if="post.description">
                  <h3>Description</h3>
                </div>
              </div>
              <div class="description" v-html="post.description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Actions from '../libraries/apiActions.js';
import { ContentLoader } from 'vue-content-loader';

export default ({
  name: "job_preview",
  components: {
      ContentLoader
  },
  metaInfo: (vue) => {
    const seo_title = vue.seo_title;
    const meta_desc = vue.meta_desc;
    const meta_keyword = vue.meta_keyword;

    return {
      title: seo_title ? seo_title : '',
      meta:[
        {name:'description',content: meta_desc ? meta_desc : ''},
        {name:'keywords',content: meta_keyword ? meta_keyword : ''},
      ]
    };
  },
  beforeMount(){
    let url = this.$route.path;
    let url_split = url.split("-");
    let id = url_split[url_split.length - 1];
    this.getPostDetails(id);
    this.isLogin = (this.$cookies.get("hire-seeker"))?true:false;
  },
  data(){
    return{
      action: new Actions(),
      post:null,
      loading:true,
      isLogin:false,
      seo_title:'',
      seo_description:''
    }
  },
  methods:{
    getPostDetails(id){
      this.action.getPost(id).then(res => {
        this.post = res.post;
        this.loading = false;
        this.seo_title = this.post.seo_title??null;
        this.meta_desc = this.post.seo_description;
        if (this.post.link && !this.post.link.match(/^https?:\/\//i)) {
          this.post.link = "https://" + this.post.link;
        }
      });
    }
  }
})
</script>
<style scoped>
.jobDetails {
  background: white;
  padding: 0px;
  border-radius: 8px 8px 0px 0px !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  border: none;
  overflow: hidden;
}
.jobHeader {
  background-image: linear-gradient(40deg, #3b63a4, #9a3b69);
  background-repeat: no-repeat;
  width: 100%;
  padding: 30px;
  color: white;
}
.jobHeader .btn-primary {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  color: white;
}
.jobHeader h1 {
  font-size: 22px;
  font-weight: bold;
  margin-right: 308px;
}
.jobHeader h2 {
  font-size: 17px;
  font-weight: normal;
  opacity: 0.5;
}
.jobHeader h3 {
  font-size: 14px;
  font-weight: normal;
  opacity: 1;
  padding: 0px;
  margin: 0px;
}
.jobDescription {
  padding: 30px;
  padding-bottom: 0px;
}
.jobDescription h3 {
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: bold;
}
.salary {
  font-size: 20px;
  margin-top: 0px;
  padding-left: 43px;
  line-height: 29px;
}
.salary p {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.jobIcon {
  width: 30px;
  display: inline-block;
  text-align: center;
  margin-left: -4px;
}
.salary .jobIcon img {
  margin-top: 4px;
  position: absolute;
  margin-left: -55px;
}
.subDetails p {
  color: #a5a5a5;
  margin-bottom: 5px;
}
.subDetails p span {
  color: #575757;
}

.relatedJobs {
  margin-top: 30px;
  background: white;
  padding: 30px;
  border-radius: 8px !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  border: none;
  overflow: hidden;
}
.relatedJobs h4 {
  font-weight: bold;
  font-size: 20px;
}

.jobBox p {
  color: #000;
  margin: 0px;
  line-height: 33px;
}
.jobBox {
  padding: 25px;
}
.jobBox p.postDate {
  text-align: right;
  margin-top: -30px;
  color: #6a6a6a;
  font-size: 13px;
}
.jobIcon {
  width: 30px;
  display: inline-block;
  text-align: center;
  margin-left: -4px;
}
.jobBox img {
  margin-top: -4px;
}
.jobTitle {
  font-size: 16px;
  font-weight: bold;
  padding-right: 150px;
}
.jobTitle span.badge {
  position: absolute;
  margin-left: 5px;
}
.jobTitle a {
  color: #007bff;
}
.howtoapply {
  font-size: 16px;
  margin-top: 20px;
}
.howtoapply h6 {
  margin-bottom: 3px;
}
.jobtxt {
  margin-right: 40px;
}

@media only screen and (max-width: 640px) {
  .jobHeader h1 {
    margin-right: 0px;
  }
  .subDetails .col-md-4 {
    padding: 0px 15px 0px 15px;
  }
  .jobtxt {
    margin-right: 40px;
  }
  .subDetails {
    padding: 15px 0px 15px 0px;
  }
  .subDetails p {
    margin: 0px 0px 10px 0px;
  }
  .salary {
    line-height: 15px;
  }
  .salary .jobIcon img {
    margin-top: -9px;
  }
  .jobHeader h2 {
    font-size: 15px;
  }
  .jobTitle {
    padding-right: 0px;
    line-height: 20px;
    font-size: 14px;
  }
  .myJobs {
    padding: 5px;
  }
  .jobBox {
    padding: 12px;
    transition: ease-out 0.1s;
    border-radius: 10px;
  }
  .jobBox:hover {
    background-color: #eef6ff;
  }
  .jobBox img {
    height: 18px;
  }
  .card {
    border-radius: 10px;
  }
  .jobBox p.postDate {
    text-align: left;
    margin-top: 0px;

    position: relative;
  }
  .jobBox p {
    padding-left: 28px;
    line-height: 22px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 13px;
  }
  .jobIcon {
    margin-left: -34px;
  }
  .jobHeader,
  .jobDescription {
    padding: 20px;
  }
  .relatedJobs {
    padding: 5px;
    padding-bottom: 25px;
  }
  .relatedJobs .col-lg-6 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .relatedJobs h4 {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 20px;
  }
  .jobHeader .jobIcon {
    margin-left: -5px;
  }
  .jobHeader .btn-primary {
    position: fixed;
    border-radius: 0px;
    right: 0px;
    left: 0px;
    z-index: 50;
    bottom: 0px;
    top: inherit;
    width: 100%;
  }

  .howtoapply {
    background: #e3f6ff;
    color: #000;
    margin-top: 15px;
    margin-right: -30px;
    margin-bottom: -30px;
    margin-left: -30px;
    padding-left: 30px;
    padding-bottom: 10px;
    line-height: 20px;

    position: fixed;
    bottom: 30px;
    z-index: 50;
    left: 30px;
    width: 100%;
    right: 0;
    padding: 0px;
    padding-top: 10px;
    border-top: solid 1px #e7e7e7;
    font-size: 14px;
  }
  .howtoapply h6 {
    font-size: 15px;
    margin-bottom: 3px;
  }
  .howtoapply div {
    padding-left: 15px;
    padding-right: 15px;
  }
  .jobHeader a.btn2.btn-primary {
    position: relative;
    left: 0px;
    width: 100%;
    margin-top: 5px;
  }
  .breadcrumb .breadcrumb-item.active {
    width: 240px;
    height: 22px;
    overflow: hidden;
    background: -webkit-linear-gradient(223deg, #eee, #007bff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>